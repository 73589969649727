import ReceiptLongIcon from "@mui/icons-material/ReceiptLong"
import GavelIcon from "@mui/icons-material/Gavel"
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"

const LicenceMenu = {
    id: 'licenceRoot',
    title: 'LICENCE',
    type: 'group',
    children: [
        {
            id: 'receipts',
            title: 'Receipts',
            type: 'item',
            url: '/receipts',
            icon: ReceiptLongIcon
        },
        {
            id: 'receiptSummaryHome',
            title: 'License Owner wage',
            type: 'item',
            url: '/receiptSummaryHome',
            icon: MonetizationOnIcon,
            breadcrumbs: false
        },
        {
            id: 'licences',
            title: 'Licences',
            type: 'item',
            url: '/licences',
            icon: GavelIcon
        },
        {
            id: 'licenceBoard',
            title: 'Licences',
            type: 'item',
            url: '/licenceBoard',
            icon: GavelIcon
        }
    ]
}

export default LicenceMenu